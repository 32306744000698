/**
 * @description
 * #### i18n config:
 * - default path /assets/locales/{{lng}}.json
 * - default language english
 * @module
 */

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/assets/locales/{{lng}}.json',
    },
    fallbackLng: localStorage.lng || 'en',
    supportedLngs: ['en'],
    /**********************************************************************
     * ns: ['common', 'home', 'profile'], list of subfolders name example,
     * change loadPath to:
     * loadPath: '/assets/locales/{{ns}}/{{lng}}.json',
     **********************************************************************/
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: false,
    },
    debug: !!process.env.REACT_APP_DEV,
  });
