import { Box, Toolbar } from '@mui/material';
import { useAppSelector } from '../store/hooks';
import { selectUser } from '../pages/Login/slice';
import { IUserValue } from '../pages/Login/interfaces';
import Header from './Header';
import Sidebar from './Sidebar';
import AppRoutes from '@app/routes';
/**
 *
 * @returns Admin panel layout & Dashboard if user logged in, or Login page
 */
const AppLayout = () => {
  const user: IUserValue = useAppSelector(selectUser).value;
  return user.loggedIn ? (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <AppRoutes />
      </Box>
    </Box>
  ) : (
    <Box component='main'>
      <AppRoutes />
    </Box>
  );
};

export default AppLayout;
