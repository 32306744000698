import { Toolbar, Box } from '@mui/material';
import { SidebarTools, AppBar } from './styled';

const Appbar = function () {
  return (
    <AppBar>
      <Toolbar disableGutters={true}>
        <SidebarTools>
          asd
          <Box
            sx={{
              width: 9,
              height: 64,
              position: 'absolute',
              right: 0,
              border: '1px solid rgba(0,0,0,0.05)',
              borderRight: '1px solid rgba(0,0,0,0.05)',
            }}
          />
        </SidebarTools>
        <Toolbar>Clipped drawer</Toolbar>
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
