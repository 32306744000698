/**
 * @module  App
 * @description Check Login and set theme, snackbar, layout.
 * @return app/layout
 */
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ReduxSnackbar from '@app/components/ReduxSnackbar';
import cfgTheme from '@app/config/theme';

import AppLayout from '@app/layout';
import theme from '@app/theme';

import { useAppDispatch } from '@app/store/hooks';
import LoginService from '@app/pages/Login/services';
import { setUser } from '@app/pages/Login/slice';

import Loading from '@app/components/Loading';
/**
 *
 * @returns app/layout
 */
const App = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [appTheme, setAppTheme] = useState({});

  useEffect(
    () => {
      setAppTheme(cfgTheme);
      localStorage.token
        ? LoginService.refreshToken(localStorage.token)
            .then((res) => dispatch(setUser(res)))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
        : setLoading(false);
    }, // eslint-disable-next-line
    []
  );

  return loading ? (
    <Box sx={{ bgcolor: '#a91f34', height: '100vh', width: '100%' }}>
      <Loading status={loading} />
    </Box>
  ) : (
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={theme(appTheme)}>
        <Box>
          <ReduxSnackbar />
          <AppLayout />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
