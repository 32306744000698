import React, { useState } from 'react';
import { Drawer, Box, Toolbar, MenuList, ListItemText, ListItemIcon, MenuItem, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import menu, { IMenu } from './config';

const drawerWidth = 240;

const Sidebar = () => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const handleClick = (val: string) => {
    setOpen({ ...open, [val]: !open[val] });
  };

  const SubMenu = (item: IMenu) => {
    if (!Array.isArray(item.path)) return null;

    return (
      <Collapse in={open[item.id]} timeout='auto' unmountOnExit key={'collapse' + item.id}>
        <MenuList sx={{ p: 0 }}>{item.path.map(createMenu)}</MenuList>
      </Collapse>
    );
  };

  const DropDownMenu = (item: IMenu, index: number) => {
    return (
      <React.Fragment key={index}>
        <MenuItem onClick={(e) => handleClick(item.id)}>
          <ListItemIcon>
            <item.icon />
          </ListItemIcon>
          <ListItemText>{item.text}</ListItemText>
          {open[item.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </MenuItem>
        {SubMenu(item)}
      </React.Fragment>
    );
  };

  const LinkMenu = (item: IMenu, index: number) => {
    if (Array.isArray(item.path)) return null;

    return (
      <Link to={item.path} key={index}>
        <MenuItem>
          <ListItemIcon>
            <item.icon />
          </ListItemIcon>
          <ListItemText>{item.text}</ListItemText>
        </MenuItem>
      </Link>
    );
  };

  const createMenu = (item: IMenu, i: number) => {
    return Array.isArray(item.path) ? DropDownMenu(item, i) : LinkMenu(item, i);
  };

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflowY: 'scroll', height: '100vh' }}>
        <MenuList>{menu.map(createMenu)}</MenuList>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
