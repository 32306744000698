/**
 * @description
 * #### Axios config:
 * - baseUrl,
 * - contentType,
 * - interceptor (dispatch snackbar on response error)
 * @module
 */
import axios from 'axios';
import { store } from '../store';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.response.use(
  (res) => {
    console.log(res);
    return res;
  },
  (err) =>
    store.dispatch({ type: 'snackbar/setSnackbar', payload: { open: true, message: err.message, severity: 'error' } })
);
