import { createTheme } from '@mui/material/styles';

const Theme = (props: any) => {
  return createTheme({
    palette: {
      primary: {
        main: props.primary,
      },
      secondary: {
        main: props.secondary,
      },
      text: {
        primary: props.text.primary,
      },
    },
  });
};

export default Theme;
