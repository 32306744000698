import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import rootReducers from './slices';

export const store = configureStore({
  reducer: rootReducers,
  middleware: (fn) => fn().concat(logger),
  devTools: !!process.env.REACT_APP_DEV,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
