/**
 * @module ./index
 * @description 
 * #### Import configs:
 * - [Redux store](./app_store.html)
 * - [Axios](./app_config_axios.html)
 * - [i18n](./app_config_i18n.html)
 * 
 * #### Create React root element 
 * @render [App Component](./App.html)
 */

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '@app/store';
import '@app/config/axios';
import '@app/config/i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
