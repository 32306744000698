import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface ISnackbarValue {
    open: boolean;
    message?: string;
    severity?:'success'|'warning'|'error'|'info';
}

export interface ISnackbarState {
  value: ISnackbarValue
}

const initialState: ISnackbarState = {
    value:{
      open:false,
      severity:'warning'
    }
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<ISnackbarValue>)  => {
      const res = action.payload;
      state.value = {...state.value, ...res};
    },
  },

});

export const { setSnackbar } = snackbarSlice.actions;
export const selectSnackbar = (state: RootState) => state.snackbar;
export default snackbarSlice.reducer;
