import { lazy } from 'react';
import { IRoute } from './interface';

const Routes: IRoute[] = [
  {
    path: '/login',
    component: lazy(() => import('../pages/Login')),
    enable: (user) => {
      return !user.loggedIn;
    },
  },
  {
    path: '/dashboard',
    component: lazy(() => import('../pages/Dashboard')),
    enable: (user) => {
      return user.loggedIn;
    },
  },
];

export default Routes;
