import { Box } from '@mui/material';

const style = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: Number.MAX_SAFE_INTEGER,
  background: 'rgba(0,0,0,0.1)',
  height: '100vh',
  width: '100%',
};

const Loading = ({ status = false }: { status: boolean }) => {
  return <Box sx={{ ...style, display: status ? 'block' : 'none' }} />;
};

export default Loading;
