import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IUserState, IUserValue } from './interfaces';
const initialState: IUserState = {
  value: {
    id: 0,
    name: 'Guest',
    email: 'guest@guest.guest',
    role_id: 0,
    permissions: [],
    loggedIn: false,
    iat: 1,
    exp: 1,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserValue>) => {
      const res = action.payload;
      state.value = { ...state.value, ...res };
    },
    logoutUser: (state, action: PayloadAction<any>) => {
      console.log(initialState);
      state.value = initialState.value;
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
