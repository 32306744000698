/**
 * @description This is description file.
 * @module
 */
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { IForm, IUserValue } from './interfaces';

const bearer = (token: string) => {
  return 'Bearer ' + token;
};

const setToken = (token: string) => {
  localStorage.token = token;
  axios.defaults.headers.common['Authorization'] = bearer(token);
  const user: IUserValue = { ...jwt_decode(token), loggedIn: true };
  return user;
};

/**
 *
 * @param data Login Form data
 * @returns user json data
 */
export const login = async (data: IForm) => {
  try {
    const res = await axios.post('/user/login', data);
    const user = setToken(res.data.result.token);
    return user;
  } catch (err) {
    return false;
  }
};
/**
 *
 * @param token localstoreage token
 * @returns token user logged in user data object or error if token not valid
 *
 */
export const refreshToken = async (token: string) => {
  axios.defaults.headers.common['Authorization'] = bearer(token);
  const res = await axios.post('/user/refresh-token', { token });
  const user = setToken(res.data.result.token);
  return user;
};
// eslint-disable-next-line
export default { login, refreshToken };
