import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
}));

export const SidebarTools = styled(MuiToolbar)(() => ({
  minWidth: '240px',
  //borderRight: '1px solid rgba(0,0,0,0.1)',
}));
