import { SvgIconProps } from '@mui/material';
import Dashboard from '@mui/icons-material/Dashboard';
import People from '@mui/icons-material/People';
import Settings from '@mui/icons-material/Settings';
import Groups from '@mui/icons-material/Groups';
import Storage from '@mui/icons-material/Storage';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import TableChartIcon from '@mui/icons-material/TableChart';

export interface IMenu {
  id: string;
  text: string;
  icon: (props: SvgIconProps) => JSX.Element;
  path: string | IMenu[];
  role: Set<number>;
}

const sidebarMenu: IMenu[] = [
  {
    id: '1',
    text: 'Dashboard',
    icon: Dashboard,
    path: '/',
    role: new Set(),
  },
  {
    id: '2',
    text: 'Contacts',
    icon: People,
    path: '/contacts',
    role: new Set(),
  },
  {
    id: '3',
    text: 'Settings',
    icon: Settings,
    role: new Set(),
    path: [
      {
        id: '3.1',
        text: 'Users',
        icon: Groups,
        path: '/settings/users',
        role: new Set(),
      },
      {
        id: '3.2',
        text: 'Server',
        icon: Storage,
        path: '/settings/server',
        role: new Set(),
      },
      {
        id: '3.3',
        text: 'Role',
        icon: VerifiedUser,
        path: '/settings/role',
        role: new Set(),
      },
      {
        id: '3.4',
        text: 'Support Table',
        icon: TableChartIcon,
        path: [
          {
            id: '3.4.1',
            text: 'Users',
            icon: Groups,
            path: '/settings/users',
            role: new Set(),
          },
        ],
        role: new Set(),
      },
    ],
  },
];

export default sidebarMenu;
