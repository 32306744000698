import { Routes, Route, Navigate } from 'react-router-dom';
import RoutesConfig from './config';

import { useAppSelector } from '@app/store/hooks';
import { selectUser } from '../pages/Login/slice';
import { IUserValue } from '../pages/Login/interfaces';

import Error404 from '../pages/Error/404';
import { IRoute } from './interface';
import { Box } from '@mui/material';
const AppRoutes = function () {
  const user: IUserValue = useAppSelector(selectUser).value;
  let routes: JSX.Element[] = [];

  if (user.loggedIn) {
    RoutesConfig.forEach((route: IRoute, i: number) => {
      route.enable(user) && routes.push(<Route key={i} path={route.path} element={<route.component />} />);
    });

    routes.push(<Route key='redirect' path='/*' element={<Navigate replace to='/dashboard' />} />);
  } else {
    RoutesConfig.forEach((route: IRoute, i: number) => {
      route.enable(user) && routes.push(<Route key={i} path={route.path} element={<route.component />} />);
    });
    routes.push(<Route key='redirect' path='/*' element={<Navigate replace to='/login' />} />);
  }

  routes.push(<Route key='redirect' path='/*' element={<Error404 />} />);
  return (
    <Box>
      <Routes>{routes}</Routes>
    </Box>
  );
};

export default AppRoutes;
